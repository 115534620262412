<template>
  <div class="subMemWrap">
    <ul class="subPT_1">
      <h3>
        {{ $t('front.common.lowerPartnerList') }}
        <div class="switch-box" v-if="treeOption">
          {{ $t('front.common.directUser') }}
          <input type="checkbox" class="set-switch" v-model="isTreeOption" @click="onChangeTreeOption" />
        </div>
      </h3>
      <li v-for="(item1) in partnerHierarchyList" :key="item1.memId" :class="{'morebar':item1.open}">
        <span class="arr" v-if="item1.cnt" :class="[item1.partnerLevel, {'moreOn':item1.open}]" @click="getHierarchyList(item1.memId, 'sub', item1)"></span>
        <span class="arr normal" v-if="!item1.cnt"></span>
        <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="onClickMemId(item1.memId, item1)">
          <span class="box" :class="item1.partnerLevel">{{ item1.partnerLevelName }}</span>
          {{item1.memId}}
        </a>

        <ul class="subPT_2" v-show="item1.open && item1.children">
          <li v-for="item2 in item1.children" :key="item2.memId" :class="{'morebar':item2.open}">
            <span class="arr" v-if="item2.cnt" :class="[item2.partnerLevel,{'moreOn':item2.open}]" @click="getHierarchyList(item2.memId, 'sub', item2)"></span>
            <span class="arr normal" v-if="!item2.cnt"></span>
            <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="onClickMemId(item2.memId, item2)">
              <span class="box" :class="item2.partnerLevel">{{ item2.partnerLevelName }}</span>
              {{item2.memId}}
            </a>

            <ul class="subPT_3" v-show="item2.open && item2.children">
              <li v-for="item3 in item2.children" :key="item3.memId" :class="{'morebar':item3.open}">
                <span class="arr" v-if="item3.cnt" :class="[item3.partnerLevel,{'moreOn':item3.open}]" @click="getHierarchyList(item3.memId, 'sub', item3)"></span>
                <span class="arr normal" v-if="!item3.cnt"></span>
                <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="onClickMemId(item3.memId, item3)">
                  <span class="box" :class="item3.partnerLevel">{{ item3.partnerLevelName }}</span>
                  {{item3.memId}}
                </a>

                <ul class="subPT_4" v-show="item3.open && item3.children">
                  <li v-for="item4 in item3.children" :key="item4.memId" :class="{'morebar':item4.open}">
                    <span class="arr" v-if="item4.cnt" :class="[item4.partnerLevel,{'moreOn':item4.open}]" @click="getHierarchyList(item4.memId, 'sub', item4)"></span>
                    <span class="arr normal" v-if="!item4.cnt"></span>
                    <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="onClickMemId(item4.memId, item4)">
                      <span class="box" :class="item4.partnerLevel">{{ item4.partnerLevelName }}</span>
                      {{item4.memId}}
                    </a>

                    <ul class="subPT_5" v-show="item4.open && item4.children">
                      <li v-for="item5 in item4.children" :key="item5.memId" :class="{'morebar':item5.open}">
                        <span class="arr" v-if="item5.cnt" :class="[item5.partnerLevel,{'moreOn':item5.open}]" @click="getHierarchyList(item5.memId, 'sub', item5)"></span>
                        <span class="arr normal" v-if="!item5.cnt"></span>
                        <a :class="[item5.partnerLevel, { 'active': selectedLi === item5.memId }]" @click="onClickMemId(item5.memId, item5)">
                          <span class="box" :class="item5.partnerLevel">{{ item5.partnerLevelName }}</span>
                          {{item5.memId}}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getPartnerHierarchyV2 } from '@/api/v2/partner'
export default {
  name: 'PartnerHierarchy',
  props: {
    treeOption: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState([
      'userData'
    ]),
    isTreeOption () {
      return this.treeOption === 'Y'
    }
  },
  data () {
    return {
      RateOpen: false,
      PayMopen: false,
      selectedLi: null,
      partnerHierarchyList: null
    }
  },
  watch: {
    partnerHierarchyList () {
      console.log('partnerHierarchyList : ', this.partnerHierarchyList)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    await this.getHierarchyList('', 'main', null)
    // await this.loadSubList(this.userData.memId, 1)
    this.selectedLi = this.userData.memId
    this.emitter.emit('Loading', false)
  },
  methods: {
    async onChangeTreeOption () {
      this.$emit('onUpdateTreeOption', this.treeOption === 'Y' ? 'N' : 'Y')
    },
    onClickMemId (memId, item) {
      this.selectedLi = memId
      this.$emit('onClickMemId', memId, 'sub', { ...item })
    },
    getHierarchyList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId,
        treeOption: this.treeOption
      }
      return getPartnerHierarchyV2(params).then(res => {
        const result = res.data
        console.log('getPartnerHierarchyV2 : ', params, result)
        const list = result.data.hierarchy
        if (type === 'sub') {
          item.children = list
          item.open = !item.open
          if (this.selectedLi === memId) {
            this.selectedLi = memId
          } else {
            this.selectedLi = memId
          }

          // this.loadSubList(memId, 1, type)
        } else {
          this.partnerHierarchyList = list
        }

        this.emitter.emit('Loading', false)

        return true
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.partnerTop {display: flex;align-items: center;justify-content: space-between;margin-bottom: 17px;}
.fx-center-end {display: flex;align-items: center;gap: 10px;}
.selectBox {
  height: 42px !important;
  padding: 12px 11px;
  box-sizing: border-box;
  border: solid 1px #cfcfcf !important;
  font-size: 15px !important;
  margin-bottom: 18px;
}
.input {
  width: 300px;
  height: 42px !important;
  padding: 12px 11px !important;
  box-sizing: border-box;
  border: solid 1px #cfcfcf;
  background-color: #fff;
  margin-bottom: 18px;
  font-size: 15px !important;
}
.select {display: flex;align-items: center;gap: 3px;}
.select a {display: flex;align-items: center;justify-content: center;height: 30px;padding: 0 15px;border-radius: 3px;border: solid 1px #b1b8bb;box-sizing: border-box;background-color: #fff;font-size: 15px;color: #6b737c;cursor: pointer;}
.select a.on, .select a:hover {color: #fff;border: solid 1px #505459;background-color: #575b60;}

.partnerInfo {padding: 12px 10px 30px;margin-bottom: 30px;box-sizing: border-box;display: flex;flex-direction: column;gap: 30px;}
h4.title {font-size: 20px;font-weight: bold;letter-spacing: -1px;color: #000;margin-bottom: 10px;}
.partnerInfo ul {display: flex;flex-direction: column;gap: 12px;width: 273px;}
.partnerInfo ul li:first-child {font-size: 15px;font-weight: bold;color: #000;box-shadow: none;border: 0;}
.partnerInfo ul li {    display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e7e7;
  box-sizing: border-box;
  background-color: #fff;font-weight: bold;color: #3b3b3b;}
.partnerInfo ul li em {display: flex;
    align-items: center;
    justify-content: space-between;height: 30px;
  letter-spacing: -0.75px;
  border-bottom: 1px solid #e7e7e7;padding: 0 13px;}
.partnerInfo ul li span {display: flex;
    align-items: center;
    justify-content: space-between;height: 46px;font-size: 13px;letter-spacing: -0.65px;padding: 0 13px;}
.infoView {display: flex;align-items: center;justify-content: center;gap: 50px;}
.btnAdd {display: flex;align-items: center;margin-left: auto;margin-right: 50px;height: 27px;padding: 0 18px;border-radius: 4px;background-color: #363b72;color: #fff;font-size: 13px;}
.btn-select-wrap {display: flex;align-items: center;justify-content: center;}
.btn-select-wrap select {min-width: auto !important;height: 27px !important;border: solid 1px #c9c9c9 !important;padding: 0 0 0 5px !important;border-radius: 4px 0 0 4px !important;}
.btn-select-wrap .btnp {border-radius: 0 4px 4px 0 !important;}
.btnp {
  height: 27px;
  padding: 0px 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #8376d4;
  color: #fff;
  display: flex;
  align-items: center;cursor: pointer;
}
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .partnerTop {flex-direction: column;gap: 15px;}
}
.subPT_1 li {
  position: relative;
}
.allBtn {
  background: #e50000;
  color: #fff;
  width: 57px;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.switch-box {margin-left: auto;display: flex;align-items: center; font-size: 12px;}
input[type="checkbox"].set-switch {
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  width: 35px;
  height: 15px !important;
  border-radius: 3em !important;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1) inset;
  border: solid 1px #b0b0b0;
  box-sizing: border-box;
  background-color: #eee;
  transition: background-color 0.15s ease-in-out;
  position: relative;
  max-width: 40px;
  min-width: auto !important;
  padding: 0 !important;
}
.set-switch:after {
  position: absolute;
  content: "";
  left: 0;
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 2px 1px 5px 0px rgb(0 0 0 / 41%);
}
input[type="checkbox"].set-switch:checked {
  background: #15e41c;
}
input[type="checkbox"].set-switch:checked:after {
  left: calc(100% - 13px);
}
</style>
